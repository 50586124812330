export type TypeInfoField = {
    key: string,
    text: string,
    value: string,
}

export type EventType = {
    key: string;
    template: string;
    fields: TypeInfoField[];
}

const birthdayEventType: EventType = {
    key: 'birthday',
    template: 'BookingRequestBirthday',
    fields: [
        {
            key: 'age',
            text: 'Birthday guest\'s new age',
            value: '',
        },
        {
            key: 'name',
            text: 'Birthday guest\'s name',
            value: '',
        },
        {
            key: 'color',
            text: 'Favorite colors',
            value: '',
        },
        {
            key: 'hobbies',
            text: 'Interests/hobbies/favorites',
            value: '',
        },
    ]
};

const graduationEventType: EventType = {
    key: 'graduation',
    template: 'BookingRequestGraduation',
    fields: [
        {
            key: 'name',
            text: 'Graduate\'s name',
            value: '',
        },
        {
            key: 'color',
            text: 'School colors',
            value: '',
        },
        {
            key: 'hobbies',
            text: 'Interests/hobbies/favorites/future plans',
            value: '',
        },
    ]
};

const babyRevealEventType: EventType = {
    key: 'babyReveal',
    template: 'BookingRequestBabyReveal',
    fields: [
        {
            key: 'name',
            text: 'Parents-to-be\'s names',
            value: '',
        },
        {
            key: 'color',
            text: 'Favorite colors',
            value: '',
        },
        {
            key: 'theme',
            text: 'Nursery theme',
            value: '',
        },
        {
            key: 'dueDate',
            text: 'Due date',
            value: '',
        }
    ]
};

const schoolEventEventType: EventType = {
    key: 'schoolEvent',
    template: 'BookingRequestSchoolEvent',
    fields: [
        {
            key: 'name',
            text: 'School name',
            value: '',
        },
        {
            key: 'description',
            text: 'Event description',
            value: '',
        }
    ]
};

const corporateEventEventType: EventType = {
    key: 'corporateEvent',
    template: 'BookingRequestCorporate',
    fields: [
        {
            key: 'signText',
            text: 'Chalkboard sign message',
            value: '',
        },
    ]
};

const neighborhoodEventEventType: EventType = {
    key: 'neighborhoodEvent',
    template: 'BookingRequestNeighborhood',
    fields: [
        {
            key: 'signText',
            text: 'Chalkboard sign message',
            value: '',
        },
    ]
};

const otherEventType: EventType = {
    key: 'other',
    template: 'BookingRequestOther',
    fields: [
        {
            key: 'description',
            text: 'Event description',
            value: '',
        },
    ]
};

const typeInfoFields: (Record<string, EventType>) = {
    'birthday': birthdayEventType,
    'graduation': graduationEventType,
    'babyReveal': babyRevealEventType,
    'schoolEvent': schoolEventEventType,
    'corporateEvent': corporateEventEventType,
    'neighborhoodEvent': neighborhoodEventEventType,
    'other': otherEventType
};

export default typeInfoFields;
