import React from 'react'
import { Link } from 'react-router-dom';

import './Sitemap.scss';

const Sitemap = () => {
    return (
        <div id='sitemap-container'>
            <Link className='link' to='/'>Home</Link>
            <Link className='link' to='/#story'>Story</Link>
            <Link className='link' to='/#menu'>Menu</Link>
            <Link className='link' to='/pricing'>Pricing</Link>
            <Link className='link' to='/contact'>Contact</Link>
        </div>
    );
}

export default Sitemap;
