import React from 'react';
import './Footer.scss';

import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const year = new Date().getFullYear();

    const scrollToTopOnSamePageLink = (path: string) => {
        if(window.location.pathname === path) {
            window.scrollTo(0, 0);
        }
    }

    return (
        <div id='footer'>
            <div id='links'>
                <div className='link-group'>
                    <div className='link-group-title'>Pages</div>
                    <ul>
                        <li><Link to='/' onClick={() => scrollToTopOnSamePageLink('/')}>Home</Link></li>
                        <li><Link to='/#story-wrapper'>Our Story</Link></li>
                        <li><Link to='/#menu'>Ice Cream Menu</Link></li>
                        <li><Link to='/pricing' onClick={() => scrollToTopOnSamePageLink('/pricing')}>Pricing</Link></li>
                    </ul>
                </div>
                <div className='link-group'>
                    <div className='link-group-title'>Get In Touch</div>
                    <ul>
                        <li><Link to='/contact'>Booking Request Form</Link></li>
                        <li><a href="tel:585-737-6437">Phone: 585-737-6437</a></li>
                    </ul>
                </div>
            </div>
            <div id='social'>
                <a href='https://www.twitter.com/JoJosCoolBus' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={ faTwitter } /></a>
                <a href='https://www.facebook.com/JoJosCoolBus' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={ faFacebook } /></a>
                <a href='https://www.instagram.com/JoJosCoolBus' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={ faInstagram } /></a>
            </div>
            <div id='trademark'>© {year} JoJo's Ice Cream Bus</div>
            <div id='powered'>Powered by <a href='https://www.techreachconsulting.com' target='_blank' rel='noopener noreferrer'>TechReach Consulting</a></div>
        </div>
    );
}

export default Footer;
