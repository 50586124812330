import axios from "axios";
import CreateEmailRequest from "./models/CreateEmailRequest";

const apiUrl: string = 'https://8jpgvsz3sd.execute-api.us-east-1.amazonaws.com/api';

class ContactService {
    async sendEmail(createEmailRequest: CreateEmailRequest) {
        const endpoint = '/email';
        const url = `${apiUrl}${endpoint}`;

        const data = await axios({
            method: 'POST',
            url,
            data: createEmailRequest,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return data;
    }
}

export default ContactService;
