import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import './App.scss';
import Home from './components/Home/Home';
import Pricing from './components/Pricing/Pricing';
import Contact from './components/Contact/Contact';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import BurgerNav from './components/NavBar/BurgerNav';
import NotFound from 'components/NotFound/NotFound';
import Sitemap from 'components/Sitemap/Sitemap';

const App = () => {
    return (
        <>
            <NavBar />
            <BurgerNav />

            <Routes>

                { /* aws sucks so I'm writing the redirect in here */ }
                <Route path='/ourstory' element={<Navigate replace to='/#story' /> } />
                <Route path='/contact-us' element={<Navigate replace to='/contact' /> } />
                <Route path='/ice-cream-menu' element={<Navigate replace to='/#menu' /> } />
                <Route path='/shop' element={<Navigate replace to='/' /> } />
                <Route path='/online-payment' element={<Navigate replace to='/' /> } />

                <Route path="/" element={<Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />

                <Route path="/sitemap" element={<Sitemap />} />
                <Route path="/404" element={<NotFound />} />

                <Route path="*" element={ <Navigate to='/404' />} />
            </Routes>
            <Footer />
        </>
    );
};

export default App;
