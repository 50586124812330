import React, { useState } from 'react';

import { scroll } from 'utils';
import ContactService from 'services/contactService';
import EventTypes, { EventType } from 'services/models/EventTypes';
import EventFields from './EventFields';
import logo from  '../../media/logo.png';
import CreateEmailRequest from 'services/models/CreateEmailRequest';
import './Contact.scss';

const Contact = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [guestNumber, setGuestNumber] = useState<string>('');
    const [eventType, setEventType] = useState<EventType>({ key: "", template: "", fields: [] });
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [errorOpacity, setErrorOpacity] = useState<number>(0);
    const [errorDisplay, setErrorDisplay] = useState<string>('none');

    const clearFields = () => {
        setName('');
        setEmail('');
        setPhoneNumber('');
        setAddress('');
        setDate('');
        setGuestNumber('');
        setEventType({ key: "", template: "", fields: [] });
        setErrorOpacity(0);
        setErrorDisplay('none');
    };

    const toggleErrorMessage = (display: boolean) => {
        if(display) {
            setErrorDisplay('flex');
            setTimeout(() => {
                setErrorOpacity(1)
            }, 250)
        } else {
            setErrorOpacity(0);
            setTimeout(() => {
                setErrorDisplay('none')
            }, 500);
        }
    };

    const sendEmail = async () => {
        const contactService = new ContactService();

        if(!formCanBeSubmitted()) {
            toggleErrorMessage(true);
            scroll('#contact');

            setTimeout(() => {
                toggleErrorMessage(false);
            }, 5000)
            return;
        }

        if(eventType) {
            const request = new CreateEmailRequest(name, email, phoneNumber, address, date, guestNumber, eventType);
            try {
                await contactService.sendEmail(request);
                setSubmitted(true);
                clearFields();
            } catch(err) {
                console.error('something went wrong when submitting the form')
            }
        }
    }

    const onEventTypeChange = (value: string) => {
        const newEventType = EventTypes[value];

        if(!newEventType) {
            console.error('failed to get event type');
            return;
        }

        setEventType(newEventType);
    }

    const onEventTypeFieldChange = (key: string, value: string) => {
        if(!eventType) {
            return;
        }

        const updated = { ...eventType };
        const field = updated.fields.find(field => field.key === key);

        if(field) {
            field.value = value;
        }

        setEventType(updated);
    }

    const formCanBeSubmitted = () => {
        return name !== '' &&
                email !== '' &&
                phoneNumber !== '' &&
                address !== '' &&
                date !== '' &&
                guestNumber !== '' &&
                eventType.key !== "";
    }

    const submittedJSX = (
        <div id='contact'>
            <div id='submitted-text' className={submitted ? 'show' : 'hide'}>
                Thank you for submitting your event request! We will be in contact to review our availability and hear more about your event!
            </div>
            <div id='logo'>
                <img src={logo} alt='JoJos ice cream bus logo' id='logo-image' />
            </div>
        </div>
    );

    const formJSX = (
        <div id='contact'>
            <div id='contact-text'>
                To book JoJo's Cool Bus for your event, please complete the form below and we will contact you to review our availability!
                <div id='contact-number'>
                    You can also call us at <a href="tel:585-737-6437">585-737-6437</a>.
                </div>
            </div>

            <div id='contact-form'>
                <div id='error-message' style={{opacity: errorOpacity, display: errorDisplay}}>Please fill out all required fields before submitting.</div>

                <h2>Contact Us</h2>

                <div id='contact-first-row'>
                    <div className='required-input-wrapper'>
                        <label>First and Last Name</label>
                        <input type='text' required value={name} onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className='required-input-wrapper'>
                        <label>Email Address</label>
                        <input type='email' required value={email} onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div className='required-input-wrapper'>
                        <label>Cell Phone Number</label>
                        <input type='tel' pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}' required value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
                    </div>
                </div>
                <div className='required-input-wrapper'>
                    <label>Date and Time of Event</label>
                    <input type='text' required value={date} onChange={e => setDate(e.target.value)}/>
                </div>
                <div className='required-input-wrapper'>
                    <label>Location of Event</label>
                    <input type='text' required value={address} onChange={e => setAddress(e.target.value)}/>
                </div>
                <div className='required-input-wrapper'>
                    <label>Number of Guests</label>
                    <input type='text' required value={guestNumber} onChange={e => setGuestNumber(e.target.value)}/>
                </div>
                <div className='required-input-wrapper'>
                    <label>Type of Event (Birthday, Graduation, Corporate, etc)</label>
                    <select required value={eventType.key} onChange={e => onEventTypeChange(e.target.value)}>
                        <option value="" disabled>Choose an event type</option>
                        <option value="birthday">Birthday</option>
                        <option value="graduation">Graduation</option>
                        <option value="babyReveal">Baby reveal</option>
                        <option value="schoolEvent">School event</option>
                        <option value="corporateEvent">Corporate event</option>
                        <option value="neighborhoodEvent">Neighborhood event</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                {eventType && (
                    <EventFields eventType={eventType} onEventTypeFieldChange={onEventTypeFieldChange} />
                )}
                <button type='submit' onClick={sendEmail} id='submit-btn'>Send</button>
            </div>
        </div>
    );

    return submitted ? submittedJSX : formJSX;
}

export default Contact;
