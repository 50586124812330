import React from 'react'
import './Home.scss';

import { ReactComponent as Svg1 } from '../../media/svg1.svg';
import { ReactComponent as Svg2 } from '../../media/svg2.svg';
import { ReactComponent as Svg3 } from '../../media/svg3.svg';
import bus from  '../../media/bus2.jpg';
import MenuItemService from 'services/menuItemsService';
import { Link } from 'react-router-dom';
import Menu from 'components/Menu/Menu';

const Home = () => {
    const menuItemService = new MenuItemService();
    const menu1Items = menuItemService.getMenuItems(1) ?? [];
    const menu2Items = menuItemService.getMenuItems(2) ?? [];
    const menu3Items = menuItemService.getMenuItems(3) ?? [];

    return (
        <div id='home-container'>
            <div id='content'>
                <div id='block-1'>
                    <div id='title'>
                        JoJo's Cool Bus
                    </div>

                    <Svg1 className='yellow-wave' />
                    <div className='yellow-spacer' />
                    <img src={bus} alt='JoJos ice cream bus' id='main-truck' />
                </div>

                <div id='block-2'>
                    Want JoJo's Cool Bus at your next event?
                    <button><Link to='/contact'>Book Now</Link></button>
                </div>

                <div id='menu'>
                    <div id='title'>The Menu</div>
                    <Svg2 className='menu-svg' id='top-menu-svg' preserveAspectRatio="none" />
                    <div className='menu-img-wrapper'>
                        <Menu title="Prices range from $1.50 to $4.00*" items={[...menu1Items, ...menu2Items, ...menu3Items]} />
                    </div>
                    <Svg3 className='menu-svg' id='bottom-menu-svg' preserveAspectRatio="none"/>
                    <div id='subtitle'>(* menu and pricing subject to change and availability)</div>
                </div>

                <div id='story-wrapper'>
                    <div id='story-title'>
                        OUR STORY
                    </div>
                    <div id='story'>
                        We are a family owned & operated Ice Cream Truck business located in West Henrietta, NY. The business was originally owned and operated by the Harter Family of Spencerport. Joanne (the other Jo Jo) began driving for the Harters during the summers of 2018 & 2019, and fell in love with this sweet bus! When Tom and Jo made the decision to sell, we knew in our hearts we needed to carry on the tradition!

                        <div id='story-mission'>Our mission remains the same: to restore the ice cream truck traditions of yester-year at a reasonable price.</div>

                        We offer a product line of over 20 different pre-packaged ice cream treats along with other fun kid favorites! JoJo's Cool Bus is available for private events such as birthday parties, graduations, weddings, anniversaries, youth sporting events, school functions, corporate picnics, or any other special occasion. To inquire about booking JoJo's Cool Bus, please complete our Booking Request Form.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
