import React from 'react'
import { Link } from 'react-router-dom';

import logo from  '../../media/logo.png';
import './NotFound.scss';

const NotFound = () => {
    return (
        <div id='not-found-container'>
            <h2>Whoops, looks like we couldn't find that page.</h2>
            <span id='not-found-text'><Link to="/">Click here</Link> (or tap the bus) to head back to the homepage.</span>

            <div id='logo'>
                <Link to="/"><img src={logo} alt='JoJos ice cream bus logo' id='logo-image' /></Link>
            </div>
        </div>
    );
}

export default NotFound;
