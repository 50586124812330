import { EventType } from "./EventTypes";

class CreateEmailRequest {
    name: string;
    email: string;
    phoneNumber: string;
    address: string;
    date: string;
    numGuests: string;
    eventTypeData: Record<string, string>;
    template: string;

    constructor(name: string, email: string, phoneNumber: string, address: string, date: string, numGuests: string, eventType: EventType) {
        this.name = name;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.address = address;
        this.date = date;
        this.numGuests = numGuests;
        this.template = eventType.template;

        const eventTypeData: Record<string, string> = {};
        eventType.fields.forEach(field => eventTypeData[field.key] = field.value)

        this.eventTypeData = eventTypeData;
    }
}

export default CreateEmailRequest;
