import { MenuItem } from 'components/Menu/Menu';

import spongebob from '../media/icecream/spongebob.png';
import fudgesicle from '../media/icecream/fudgesicle.png';
import strawberry from '../media/icecream/strawberry.png';
import blueRaspberry from '../media/icecream/blueRaspberry.png';
import creamsicle from '../media/icecream/creamsicle.png';
import vanilla from '../media/icecream/vanilla.png';
import icecreamsandwich from '../media/icecream/icecreamsandwhich.png';
import drumstick from '../media/icecream/drumstick.png';
import popsicle from '../media/icecream/popsicle.png';
import chocolateCrunchCake from '../media/icecream/chocolateCrunchCake.png';
import sourCyclone from '../media/icecream/sourCyclone.png';
import watermelonCyclone from '../media/icecream/watermelonCyclone.png';
import rainbowPolarPole from '../media/icecream/rainbowPolarPole.png';
import cottonCandyTwirl from '../media/icecream/cottonCandyTwirl.png';
import caramelMacchiato from '../media/icecream/caramelMacchiato.png';

import bombPopJr from '../media/icecream/bombPopJr.png';
import jollyrancher from '../media/icecream/jollyrancher.png';
import candyClash from '../media/icecream/candyClash.png';
import snowcone from '../media/icecream/snowcone.png';
import spiderman from '../media/icecream/spiderman.png';
import batman from '../media/icecream/batman.png';

import chocochip from '../media/icecream/chocochip.png';
import oreo from '../media/icecream/oreo.png';
import oreoBar from '../media/icecream/oreoBar.png';
import snickers from '../media/icecream/snickers.png';
import twix from '../media/icecream/twix.png';
import reesesCookie from '../media/icecream/reesesCookie.png';
import coldBrew from '../media/icecream/coldBrew.png';
import tmnt from '../media/icecream/tmnt.png';
import avalanche from '../media/icecream/avalanche.png';

import blueRibbon from '../media/icecream/blueRibbon.png';
import bubblegumSnowCone from '../media/icecream/bubblegumSnowCone.png';
import cookiewich from '../media/icecream/cookiewich.png';
import screamers from '../media/icecream/screamers.png';
import rainbowIcePop from '../media/icecream/rainbowIcePop.png';
import orangePushPop from '../media/icecream/orangePushPop.png';
import bigNeapolitan from '../media/icecream/bigNeapolitan.png';


const twoOrLessMenu: MenuItem[] = [
    {
        image: popsicle,
        name: 'popsicle',
        alt: 'popsicle ice pop lick-a-color',
        active: false
    },
    {
        image: rainbowIcePop,
        name: 'rainbow ice pop',
        alt: 'rainbow ice pop',
        active: true
    },
    {
        image: orangePushPop,
        name: 'orange push up',
        alt: 'orange push up',
        active: true
    },
    {
        image: blueRibbon,
        name: 'rainbow push up',
        alt: 'rainbow push up',
        active: true
    },
    {
        image: creamsicle,
        name: 'orange dream',
        alt: 'orange dream ice cream bar',
        active: true
    },
    {
        image: chocolateCrunchCake,
        name: 'chocolate crunch cake',
        alt: 'chocolate crunch cake ice cream bar',
        active: true
    },
    {
        image: vanilla,
        name: 'vanilla ice cream bar',
        alt: 'vanilla ice cream bar',
        active: true
    },
     {
        image: fudgesicle,
        name: 'fudge bar',
        alt: 'fuge ice cream bar',
        active: true
    },
    {
        image: sourCyclone,
        alt: 'sour cyclone',
        name: 'sour cyclone',
        active: false
    },
    {
        image: watermelonCyclone,
        alt: 'watermelon cyclone',
        name: 'watermelon cyclone',
        active: false
    },
    {
        image: rainbowPolarPole,
        alt: 'rainbow polar pole',
        name: 'rainbow polar pole',
        active: false
    },
    {
        image: cottonCandyTwirl,
        alt: 'cotton candy twirl',
        name: 'cotton candy twirl ice cream bar',
        active: false
    },
    {
        image: blueRaspberry,
        name: 'blue raspberry',
        alt: 'blue raspberry ice cream bar',
        active: false
    },
    {
        image: drumstick,
        name: 'vanilla drumstick',
        alt: 'vanilla drumstick ice cream cone',
        active: true
    },
    {
        image: caramelMacchiato,
        name: 'caramel machiato ice cream bar',
        alt: 'caramel machiato ice cream bar',
        active: false
    },
    {
        image: coldBrew,
        name: 'cold brew coffee ice cream bar',
        alt: 'cold brew coffee ice cream bar',
        active: true
    },
    {
        image: icecreamsandwich,
        name: 'ice cream sandwich',
        alt: 'vanilla ice cream sandwich',
        active: true
    },
    {
        image: strawberry,
        name: 'strawberry shortcake',
        alt: 'strawberry shortcake ice cream bar',
        active: true
    },
    {
        image: bigNeapolitan,
        name: 'big neapolitan sandwich',
        alt: 'big neapolitan sandwich',
        active: true
    },

];

const twoFiftyMenu: MenuItem[] = [
    {
        image: spiderman,
        name: 'spiderman popsicle',
        alt: 'spiderman popsicle',
        active: true
    },
    {
        image: batman,
        name: 'batman popsicle',
        alt: 'batman popsicle',
        active: true
    },
    {
        image: spongebob,
        name: 'spongeBob',
        alt: 'spongeBob ice cream bar',
        active: true
    },
        {
        image: tmnt,
        name: 'teenage mutant ninja turtles',
        alt: 'teenage mutant ninja turtles ice cream bar',
        active: true
    },
    {
        image: snowcone,
        name: 'snowcone popsicle',
        alt: 'snowcone popsicle',
        active: false
    },
    {
        image: bubblegumSnowCone,
        name: 'snowcone popsicle',
        alt: 'snowcone popsicle',
        active: true
    },
     {
        image: bombPopJr,
        name: 'bomb pop (original)',
        alt: 'bomb pop original popsicle',
        active: true
    },
    {
        image: jollyrancher,
        name: 'jolly rancher bomb pop',
        alt: 'jolly rancher bomb pop popsicle',
        active: false
    },
    {
        image: candyClash,
        name: 'candy clash bomb pop',
        alt: 'candy clash bomb pop popsicle',
        active: true
    },

];

const threeOrMoreMenu: MenuItem[] = [
    {
        image: twix,
        name: 'twix ice cream bar',
        alt: 'twix ice cream bar',
        active: true
    },
    {
        image: snickers,
        name: 'snickers ice cream bar',
        alt: 'snickers ice cream bar',
        active: true
    },
    {
        image: chocochip,
        name: 'choc chip cookie sandwich',
        alt: 'choc chip cookie ice cream sandwich',
        active: true
    },
    {
        image: reesesCookie,
        name: 'reese\'s peanut butter cup',
        alt: 'reeses peanut butter cup ice cream',
        active: true
    },
    {
        image: oreo,
        name: 'oreo ice cream sandwich',
        alt: 'oreo cookie ice cream sandwich',
        active: false
    },
    {
        image: oreoBar,
        name: 'oreo ice cream bar',
        alt: 'oreo cookie ice cream bar',
        active: true
    },
    {
        image: avalanche,
        name: 'cookies n\' cream avalanche',
        alt: 'cookies and cream frozen dairy snack',
        active: false
    },
    {
        image: screamers,
        name: 'cookies n\' cream screamers',
        alt: 'cookies and cream frozen dairy snack',
        active: true
    },
    {
        image: cookiewich,
        name: 'byrne dairy ice cream cookiewich',
        alt: 'byrne dairy ice cream cookie sandwich',
        active: true
    },

];

const menuMap: Record<number, MenuItem[]> = {
    1: twoOrLessMenu,
    2: twoFiftyMenu,
    3: threeOrMoreMenu,
}

class MenuItemsService {
    getMenuItems = (menuId: number) => {
        return menuMap[menuId]
    }
}

export default MenuItemsService;
