import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import { scroll } from 'utils';
import './NavBar.scss';
import headerLogo from  '../../media/headerLogo.png';

const NavBar = () => {
    const onHomePage = useLocation().pathname === '/';

    const homeNavLinks = (
        <ul>
            <li><Link to='/'><img src={headerLogo} alt='JoJos ice cream bus logo' id='nav-logo-image' /></Link></li>
            <li onClick={() => scroll('#menu')}>Ice Cream Menu</li>
            <li onClick={() => scroll('#story-wrapper')}>Our Story</li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
        </ul>
    );

    const defaultNavLinks = (
        <ul>
            <li><Link to='/'><img src={headerLogo} alt='JoJos ice cream bus logo' id='nav-logo-image' /></Link></li>
            <li><Link to='/#menu'>Ice Cream Menu</Link></li>
            <li><Link to='/#story'>Our Story</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
        </ul>
    );

    return (
        <div id="nav">
            { onHomePage ? homeNavLinks : defaultNavLinks }
        </div>
    );
}

export default NavBar;
