import React from 'react'
import './Menu.scss';

export type MenuItem = {
    image: string,
    name: string,
    alt: string
    active: boolean
}


const Menu = ({ title, items }: {
    title: string;
    items: MenuItem[];
}) => {
    return (
        <>
            <h3 className="menu-title">{title}</h3>
            <div className='menu'>
                {
                    items.map(item => {
                        return item.active && (
                            <div className='menu-item' key={item.name}>
                                <img src={item.image} alt={item.alt} className='menu-item-img' />
                                <span className='menu-item-name'>{item.name}</span>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Menu;
