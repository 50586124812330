import React from 'react';
import './Pricing.scss';
import { Link } from 'react-router-dom';


const Pricing = () => {
    return (
        <div id='pricing'>
        <h2>
            Pricing
        </h2>

        <div className='pricing-section'>
            <h3>Private Events</h3>
            <div><b>30 minute minimum:</b> $60 event fee & minimum purchase of 25 ice creams </div>
            <div><b>60 minute minimum:</b> $85 event fee & minimum purchase of 25 ice creams </div>

            <div className='pricing-text'>
               The event fee is due in advance when reserving JoJo's Cool bus for your party/event. The event fee may vary depending on location of the event. This fee secures your event on our schedule and includes the following:
            </div>

            <ul>
                <li>
                    Custom, personalized message on the exterior chalkboard
                </li>
                <li>
                    Event themed music to be played from the bus' exterior speakers
                </li>
                <li>
                    Onsite for up to 30 or 60 minutes. Don’t worry, both are plenty of time for your guests to pick their favorite ice cream, snap some photos, and enjoy the experience! Based on our schedule, we may be available to stay longer for an additional fee. If you know in advance that you would like us to stay longer please let us know!
                </li>
            </ul>

            Our bus is busy with several events booked on the same day. Please choose the time you would like us to arrive so that your party will be ready to be served. We expect to start serving when we arrive at the scheduled time. Due to our schedule, we may not be able to accommodate you if your party is running late, so when you hear the music be ready for ice cream!

            At your event, JoJo's Cool Bus staff will hand out ice cream to your guests and will keep track of outgoing ice cream. Balance is due at the end of your event.

            <p>To inquire about booking JoJo's Cool Bus for your private event, please complete our <Link to="/contact" target="#">Booking Request Form.</Link></p>
        </div>

        <div className='pricing-section'>
            <h3>Corporate Events</h3>

            <div className='pricing-text'>
                Pricing for corporate events varies, so please fill out our Booking Request Form and we will be able to provide you with more information.
            </div>
        </div>

        <div className='pricing-section'>
            <h3>Community Events</h3>

            <div className='pricing-text'>
                To inquire about booking JoJo's Cool Bus for festivals, fairs, and other community events, please complete our Booking
                Request Form.
            </div>
        </div>

        <div className='pricing-section'>
            <h3>Payment Options</h3>

            <div className='pricing-text'>
                We accept cash, Venmo, PayPal, credit card, and check payments.
            </div>
        </div>

        <div id='disclaimers-wrapper'>
            <div>
                Cancellation Policy:

                If a reservation is cancelled within seven (7) days of your event, no refund will be given. If a reservation is
                cancelled more than seven (7) days prior to your event, your event fee will be returned after withholding a $10.00
                processing fee.
            </div>
        </div>
    </div>
    );
}

export default Pricing;
