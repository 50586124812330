import React from 'react';
import { EventType } from 'services/models/EventTypes';

const EventFields = ({eventType, onEventTypeFieldChange}: {
    eventType: EventType;
    onEventTypeFieldChange: (key: string, value: string) => void;
}) => {
    const fields = eventType.fields;

    return (
        <div id='type-info'>
            <div id='type-info-form'>
                {fields.map(field => {
                    return (
                        <div className='input-wrapper' key={field.key}>
                            <label>{field.text}</label>
                            <input type='text' value={field.value} onChange={e => onEventTypeFieldChange(field.key, e.target.value)}/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default EventFields;
