import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { slide as Menu, State } from 'react-burger-menu'

import { scroll } from 'utils';
import './BurgerNav.scss';
import headerLogo from  '../../media/headerLogo.png';

const BurgerNav = () => {
    const [pos, setPos] = useState<number>(0);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [barsColor, setBarsColor] = useState<string>('white');

    const wrapperRef = useRef(null);

    const burgerNavStyles = {
          bmBurgerBars: {
            background: barsColor,
            transition: 'background 0.25s linear'
        },
    }

    useEffect (() => {
        const updateBurgerNavColors = () => {
            let scrolled = document.scrollingElement!.scrollTop;
            const threshold = 90;

            if ((pos < threshold && scrolled >= threshold) || (pos > threshold && scrolled <= threshold)) {
                setBarsColor(scrolled >= threshold ? '#013FA6' : '#FCFBFC');

                setPos(scrolled);
            }
        };

        document.addEventListener("scroll", updateBurgerNavColors);

        return () => {
            document.removeEventListener('scroll', updateBurgerNavColors);
        }
    }, [pos]);

    const onBurgerNavStateChange = (state: State) => {
        setIsOpen(state.isOpen);

        if(!state.isOpen) {
            const bars = document.getElementsByClassName('bm-burger-bars');

            for(let i = 0; i < bars.length; i++) {
                bars[i].className = `bm-burger-bars ${barsColor}`;
            }
        }
    }

    const useClickOutsideMenu = (ref: any) => {
        useEffect(() => {
            const handleClickOutside = (event: MouseEvent | TouchEvent) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
                document.removeEventListener("touchstart", handleClickOutside);
            };
        }, [ref]);
    }

    useClickOutsideMenu(wrapperRef);

    const onHomePage = useLocation().pathname === '/';

    const homeNavLinks = (
        <div id='mobile-nav-wrapper'>
            <Link to='/'><img src={headerLogo} alt='JoJos ice cream bus logo' id='mobile-nav-logo-image' /></Link>

            <Menu right isOpen={isOpen} onStateChange={onBurgerNavStateChange} disableAutoFocus id='burger-menu' styles={burgerNavStyles}>
                <Link to='/' onClick={() => setIsOpen(false)}>Home</Link>
                <div onClick={() => { scroll('#menu'); setIsOpen(false) }}>Ice Cream Menu</div>
                <div onClick={() => { scroll('#story-wrapper'); setIsOpen(false) }}>Our Story</div>
                <Link to='/pricing' onClick={() => setIsOpen(false)}>Pricing</Link>
                <Link to='/contact' onClick={() => setIsOpen(false)}>Contact Us</Link>
            </Menu>

        </div>
    );

    const defaultNavLinks = (
        <div id='mobile-nav-wrapper'>
            <Link to='/'><img src={headerLogo} alt='JoJos ice cream bus logo' id='mobile-nav-logo-image' /></Link>

            <Menu right isOpen={isOpen} onStateChange={onBurgerNavStateChange} disableAutoFocus id='burger-menu' styles={burgerNavStyles}>
                <Link to='/' onClick={() => setIsOpen(false)}>Home</Link>
                <Link to='/#menu' onClick={() => setIsOpen(false)}>Ice Cream Menu</Link>
                <Link to='/#story-wrapper' onClick={() => setIsOpen(false)}>Our Story</Link>
                <Link to='/pricing' onClick={() => setIsOpen(false)}>Pricing</Link>
                <Link to='/contact' onClick={() => setIsOpen(false)}>Contact Us</Link>
            </Menu>
        </div>
    );

    return (
        <div ref={wrapperRef}>
            { onHomePage ? homeNavLinks : defaultNavLinks }
        </div>
    );
}

export default BurgerNav;
